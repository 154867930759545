<style lang="less" scoped>
.noteCount {
  font-size: 26px;
  font-weight: bold;
  color: #fff;
  &:hover {
    color: #fa8b59;
  }
}
</style>
<template>
  <div>
    <Divider dashed><span class="divider-text">数据汇总</span></Divider>
    <div class="text-center">
      <div class="p-b-10">今日拜访笔记总数</div>
      <Tooltip
        content="点击查看详情"
        placement="right"
      >
        <a
          href="javascript:void(0);"
          class="noteCount"
          @click="handleViewNoteDetail"
        >{{noteCount}}</a>
      </Tooltip>
    </div>
    <Modal
      v-model="noteDetailModal"
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >查看拜访笔记详情</p>
      <div>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="6">
            <DatePicker
              v-model="noteDate"
              :clearable="false"
              :editable="false"
              size="small"
              format="yyyy/MM/dd"
              type="daterange"
              placement="bottom-end"
              placeholder="选择日期范围"
              style="width: 100%"
              @on-change="handleChangeNoteDate"
            ></DatePicker>
          </i-col>
          <i-col span="6">
            <Select
              v-model="detailQuery.userId"
              :clearable="true"
              size="small"
              placeholder="选择销售人员"
            >
              <Option
                v-for="item in userArray"
                :key="'userId_'+item.userId"
                :value="item.userId"
              >{{item.name}}</Option>
            </Select>
          </i-col>
          <i-col span="2">
            <Button
              size="small"
              type="primary"
              icon="ios-search"
              @click="handelSearch"
            >搜索</Button>
          </i-col>
        </Row>
        <Table
          stripe
          size="small"
          :data="detailData"
          :columns="tableColumns"
        ></Table>
        <div class="paging_style">
          <Page
            :total="detailTotal"
            :page-size="detailQuery.pageSize"
            size="small"
            show-total
            show-elevator
            :current="detailQuery.pageNumber"
            @on-change="handleChangePage"
          ></Page>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getNoteCount } from '@/api/sf/statistic'
import { getAllNotePage } from '@/api/sf/business'
export default {
  data () {
    return {
      startDate: '',
      endDate: '',
      noteCount: 0,
      noteDetailModal: false,
      noteDate: [],
      detailQuery: {
        pageNumber: 1,
        pageSize: 15,
        startDate: '',
        endDate: '',
        businessId: null,
        userId: null
      },
      detailData: [],
      detailTotal: 0,
      tableColumns: [
        { title: '商家名称', align: 'center', key: 'businessName' },
        {
          title: '联系人',
          align: 'center',
          key: 'contact',
          width: 160,
          render: (h, params) => {
            const jsonContact = JSON.parse(params.row.contact)
            const html = []
            jsonContact.forEach(element => {
              html.push(h('p', `${element.name}(${element.tel})`))
            })
            return h('div', html)
          }
        },
        { title: '销售人员', align: 'center', key: 'userName' },
        { title: '跟进状态', align: 'center', key: 'statusName' },
        { title: '无意向原因', align: 'center', key: 'reasonName' },
        { title: '备注', align: 'center', key: 'mark' }
      ]
    }
  },
  computed: {
    userArray () {
      return this.$store.state.microSituation.userArray
    }
  },
  created () {
    const date = new Date()
    this.startDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
    this.endDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`

    this.getNoteCountData()
  },
  methods: {
    getNoteCountData () {
      getNoteCount({ startDate: this.startDate, endDate: this.endDate }).then(res => {
        if (res && !res.errcode) {
          this.noteCount = res
        } else {
          this.noteCount = 0
        }
      })
    },
    getNoteDetailData () {
      getAllNotePage(this.detailQuery).then(res => {
        if (res && !res.errcode) {
          this.detailData = res.list
          this.detailTotal = res.totalRow
          this.detailQuery.pageNumber = res.pageNumber
        } else {
          this.detailData = []
          this.detailTotal = 0
          this.detailQuery.pageNumber = 1
        }
      })
    },
    handleViewNoteDetail () {
      this.noteDetailModal = true
      this.noteDate = [this.startDate, this.endDate]
      this.detailQuery.startDate = this.startDate
      this.detailQuery.endDate = this.endDate
      this.getNoteDetailData()
    },
    handelSearch () {
      this.detailQuery.pageNumber = 1
      this.getNoteDetailData()
    },
    handleChangeNoteDate () {
      if (this.noteDate.length && this.noteDate[0] && this.noteDate[1]) {
        const sDate = new Date(this.noteDate[0])
        const eDate = new Date(this.noteDate[1])
        this.detailQuery.startDate = `${sDate.getFullYear()}-${(sDate.getMonth() + 1).toString().padStart(2, '0')}-${sDate.getDate().toString().padStart(2, '0')}`
        this.detailQuery.endDate = `${eDate.getFullYear()}-${(eDate.getMonth() + 1).toString().padStart(2, '0')}-${eDate.getDate().toString().padStart(2, '0')}`
      } else {
        this.detailQuery.startDate = ''
        this.detailQuery.endDate = ''
      }
    },
    handleChangePage (curPage) {
      this.detailQuery.pageNumber = curPage
      this.getNoteDetailData()
    }
  }
}
</script>
